import * as React from 'react'

import { CmsContentPage } from '@grandvisionhq/bloomreach'
import { NextPage } from '@grandvisionhq/next'

import { withCms } from '../../cms'

const ContentPage: NextPage = () => {
  return <CmsContentPage />
}

export default withCms({ wrapInContainerPage: false })(ContentPage)
